<template>
  <v-container fluid class="pricing-zone-relations-page">
    <v-row>
      <v-col class="mb-5" cols="auto" md="12" sm="12">
        <v-card>
          <div :id="window.width < 600 ? 'pricing-zone-hourly-relations-page-step-0' : ''"></div>
          <v-data-table
            :footer-props="{
              pageText: `{0}-{1} ${$t('tables.of')} {2}`,
              itemsPerPageText: $t('tables.pricingPerHourByPage'),
              itemsPerPageOptions: [5, 10, 20, 50, 100],
            }"
            :headers="pricingZoneHourlyRelationsHeaders"
            :no-data-text="$t('tables.noDataText')"
            :no-results-text="$t('tables.noDataText')"
            :items="pricingZoneRelations"
            :search="searchPricingZoneRelationString"
            class="elevation-1 cursor-pointer"
            @click:row="editPricingZoneRelationItem"
            multi-sort>
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                  {{ $t('tables.pricingPerHour') }}
                </v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>

                <v-text-field
                  id="pricing-zone-hourly-relations-page-step-1"
                  v-model="searchPricingZoneRelationString"
                  append-icon="mdi-magnify"
                  hide-details
                  :label="$t('tables.search')"
                  class="zones-search"
                  single-line></v-text-field>

                <p :id="window.width > 599 ? 'pricing-zone-hourly-relations-page-step-0' : ''" class="first-step"></p>

                <v-autocomplete
                  v-model="clientFilter"
                  :items="clients"
                  clearable
                  item-text="name"
                  item-value="id"
                  :label="$t('invoicesPage.chooseClient')"
                  :no-data-text="$t('select.noDataAvailable')"
                  class="clients-filter ml-5"
                  @focus="$event.target.click()"></v-autocomplete>

                <v-spacer />

                <create-pricing-hourly-relation
                  :selectedItem="selectedItem"
                  :pricingZoneRelations="pricingZoneRelations"
                  :clients="clients"
                  :currencies="currencies"
                  :vehicleClasses="vehicleClasses"
                  :pricingZones="pricingZones"
                  :clientFilter="clientFilter"
                  @loadAllPricingZoneRelations="loadAllPricingZoneRelations" />

                <v-dialog v-model="pricingZoneRelationDialogDelete" max-width="650">
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ selectedItemDelete.name }}
                      <v-spacer />
                      <v-icon
                        class="mdi mdi-close"
                        style="color: white"
                        @click="closePricingZoneRelationDelete"></v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <p>
                          {{ $t('pricingZoneRelationsPage.deleteMessage') }}
                        </p>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        :disabled="!enablePricingZoneRelationSave"
                        class="primary"
                        text
                        type="submit"
                        @click="deletePricingZoneRelationItemConfirm">
                        {{ $t('buttons.yes') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <td class="text-center">
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-tooltip left>
                      <template v-slot:activator="{ on: tooltipOn }">
                        <v-btn text v-on="{ ...on, ...tooltipOn }">
                          <v-icon> mdi-dots-vertical </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{ $t('buttons.actions') }}
                      </span>
                    </v-tooltip>
                  </template>
                  <v-list>
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item @click="editPricingZoneRelationItem(item)">
                          <v-icon class="mx-1" small v-bind="attrs">mdi-pencil</v-icon>
                          <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                            {{ $t('buttons.edit') }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <span>{{ $t('tooltips.edit') }}</span>
                    </v-tooltip>

                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item @click="deleteItem(item)">
                          <v-icon class="mx-1" small v-bind="attrs">mdi-trash-can-outline</v-icon>
                          <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                            {{ $t('buttons.delete') }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <span>{{ $t('tooltips.delete') }}</span>
                    </v-tooltip>
                  </v-list>
                </v-menu>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <div class="tourStyle">
      <v-tour
        name="pricingZoneHourlyRelationsTour"
        :steps="stepsTour"
        :callbacks="myCallbacks"
        :options="options"></v-tour>
    </div>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { pricingZoneHourlyRelationsHeaders } from '@/mixins/data-table-headers';
import { defaultPricingZoneHourlyRelation } from '@/mixins/default-items';
import { getAllTourSteps, pricingZoneHourlyRelationsPageSteps } from '@/mixins/onboarding-tutorial-steps';
import { mapState } from 'vuex';
import CreatePricingHourlyRelation from '@/components/zone-pricing/CreatePricingHourlyRelation.vue';

export default {
  name: 'PricingZoneRelationsPage',
  components: { CreatePricingHourlyRelation },
  data() {
    return {
      searchPricingZoneRelationString: '',
      pricingZoneRelationDialog: false,
      pricingZoneRelationDialogDelete: false,
      pricingZoneRelationFormTitle: 'pricingZoneRelationsPage.newItem',
      selectedItem: {},
      selectedItemDelete: {},
      enablePricingZoneRelationSave: true,
      loading: false,
      pricingZoneRelations: [],
      pricingZones: [],
      vehicleClasses: [],
      currencies: [],
      myCallbacks: {
        onStop: this.onComplete,
        onNextStep: this.onNextStep,
        onSkip: this.onSkip,
      },
      steps: [],
      visitedStepsArray: [],
      stepsTour: [],
      window: {
        width: 0,
      },
      options: {
        labels: {
          buttonSkip: i18n.t('buttons.skip'),
          buttonPrevious: '<< ' + i18n.t('buttons.prev'),
          buttonNext: i18n.t('buttons.next') + ' >>',
          buttonStop: i18n.t('buttons.finish'),
        },
        highlight: true,
      },
      user: {},
      clients: [],
      clientFilter: null,
    };
  },
  async created() {
    this.user = this.$store.getters['auth/user'];
    this.selectedItem = Object.assign({}, { ...defaultPricingZoneHourlyRelation, zonePricingHourlyPrices: [] });
    await this.loadAllPricingZonesData();
    await this.loadAllPricingZoneRelations();

    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  computed: {
    ...mapState('onboardingTutorial', ['tourActive']),
    pricingZoneHourlyRelationsHeaders() {
      return pricingZoneHourlyRelationsHeaders(i18n);
    },
  },
  methods: {
    editPricingZoneRelationItem(item) {
      this.selectedItem = this.pricingZoneRelations.indexOf(item);
      this.selectedItem = Object.assign({}, item);
    },

    async loadAllPricingZonesData() {
      await this.$store.dispatch('zonePricing/getZonePricingData').then((res) => {
        this.pricingZones = res.data.zonePricings.map((item) => {
          return item;
        });

        this.vehicleClasses = res.data.vehicleClasses.map((item) => {
          return item;
        });

        this.currencies = res.data.currencies.map((item) => {
          return item;
        });

        this.clients = res.data.clients.map((item) => {
          item.active = parseInt(item.active);

          return item;
        });
      });
    },

    async loadAllPricingZoneRelations() {
      const data = { client_id: this.clientFilter };
      await this.$store.dispatch('zonePricing/getAllPricingHourRelations', data).then((res) => {
        this.pricingZoneRelations = res.data.map((e) => {
          // Initialize or update zonePricingHourlyPrices with missing currencies/vehicle classes
          e.zonePricingHourlyPrices = this.populateMissingPrices(e.zonePricingHourlyPrices);
          e.client_id = e.client?.id || '';
          e.zone_pricing_id = e.zonePricing.id;
          e.name = e.zonePricing.name;

          return e;
        });
      });

      this.setStepsAndStartTour();
    },

    populateMissingPrices(existingPrices) {
      const updatedPrices = [...existingPrices];

      // Loop through each currency and vehicle class
      this.currencies.forEach((currency) => {
        this.vehicleClasses.forEach((vehicleClass) => {
          // Check if this combination of currency and vehicle class exists
          const existingEntry = updatedPrices.find(
            (price) => price.currency_id == currency.id && price.vehicle_class_id == vehicleClass.id
          );

          // If the combination is missing, add it with default price
          if (!existingEntry) {
            updatedPrices.push({
              id: null,
              currency_id: currency.id,
              vehicle_class_id: vehicleClass.id,
              price_per_km: 0,
              price_per_hour: 0,
              price_per_waiting_hour: 0,
            });
          }
        });
      });

      return updatedPrices;
    },

    deleteItem(item) {
      this.selectedItemDelete = this.pricingZoneRelations.indexOf(item);
      this.selectedItemDelete = Object.assign({}, item);
      this.pricingZoneRelationDialogDelete = true;
    },

    async deletePricingZoneRelationItemConfirm() {
      await this.$store
        .dispatch('zonePricing/deletePricingHourRelation', this.selectedItemDelete)
        .then(() => {
          this.loadAllPricingZoneRelations();
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.deletedSuccessfully'), color: 'green' });
          this.closePricingZoneRelationDelete();
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        })
        .finally(() => {
          this.closePricingZoneRelationDelete();
        });
    },

    closePricingZoneRelationDelete() {
      this.pricingZoneRelationDialogDelete = false;
      this.$nextTick(() => {
        this.selectedItemDelete = Object.assign(
          {},
          { ...defaultPricingZoneHourlyRelation, zonePricingHourlyPrices: [] }
        );
      });
    },

    async setStepsAndStartTour() {
      if (this.$store.state.auth.role !== 1 && !this.tourActive) {
        await this.setSteps();
        if (this.stepsTour?.length > 0) {
          this.startTour();
        }
      }
    },

    setSteps() {
      return new Promise((resolve) => {
        const visitedStepsParsed = JSON.parse(localStorage.getItem('LimoExpressUser'))?.user_tour_logs;

        if (visitedStepsParsed) {
          this.visitedStepsArray = visitedStepsParsed
            .filter((item) => item.component_id === '14')
            ?.map((item) => item.step_id);
          this.steps = pricingZoneHourlyRelationsPageSteps();
          if (this.visitedStepsArray?.length > 0) {
            this.stepsTour = this.steps.filter((step) => !this.visitedStepsArray.includes(step.target));
          } else {
            this.stepsTour = this.steps;
          }
        } else {
          this.stepsTour = [];
          let user = this.$store.getters['auth/user'];
          user.user_tour_logs = getAllTourSteps();

          this.$store.dispatch('auth/setUser', user);
          localStorage.setItem('LimoExpressUser', JSON.stringify(user));
        }

        resolve();
      });
    },

    startTour() {
      this.$tours['pricingZoneHourlyRelationsTour'].start();
      this.$store.dispatch('onboardingTutorial/toggleTour', true);
    },

    onComplete() {
      this.pricingZoneDialog = false;
      this.$store.dispatch('onboardingTutorial/toggleTour', false);
      this.setStepAsVisited(this.steps.pop()?.target?.split('-').pop());
    },

    onSkip() {
      this.$store.dispatch('onboardingTutorial/toggleTour', false);

      const body = {
        objects: this.steps.map((item) => ({
          component_id: '14',
          step_id: item.target,
        })),
      };

      this.$store.dispatch('onboardingTutorial/sendVisitedStep', body);
      const lastCharsOfTarget = this.steps.map((step) => step.target.slice(-1));
      lastCharsOfTarget.forEach((step) => this.updateStorage(step));
    },

    onNextStep(currentStep) {
      const currentStepId = this.stepsTour[currentStep]?.targetId;
      this.setStepAsVisited(currentStepId);
    },

    updateStorage(currentStep) {
      const newVisitedStep = {
        component_id: '14',
        step_id: `#pricing-zone-hourly-relations-page-step-${currentStep}`,
      };

      let user = this.$store.getters['auth/user'];

      if (user) {
        if (user.user_tour_logs?.length > 0) {
          user.user_tour_logs.push(newVisitedStep);
        } else {
          user.user_tour_logs = [newVisitedStep];
        }
      }

      this.$store.dispatch('auth/setUser', user);
      localStorage.setItem('LimoExpressUser', JSON.stringify(user));
    },

    setStepAsVisited(step) {
      const body = {
        objects: [
          {
            component_id: '14',
            step_id: `#pricing-zone-hourly-relations-page-step-${step}`,
          },
        ],
      };
      this.$store.dispatch('onboardingTutorial/sendVisitedStep', body);
      this.updateStorage(step);
    },

    handleResize() {
      this.window.width = window.innerWidth;
    },
  },

  beforeDestroy() {
    document.body.classList.remove('v-tour--active');
    this.$store.dispatch('onboardingTutorial/toggleTour', false);
  },

  watch: {
    tourActive() {
      this.setStepsAndStartTour();
    },
    '$store.state.onboardingTutorial.tourReset': {
      handler() {
        if (this.$store.state.onboardingTutorial.tourReset) {
          this.setStepsAndStartTour(); // Function to start your Vue Tour

          // Clear the tour reset flag to avoid repeat triggers
          this.$store.dispatch('onboardingTutorial/clearTourReset');
        }
      },
    },
    clientFilter() {
      this.loadAllPricingZoneRelations();
    },
  },
};
</script>

<style>
.pricing-zone-relations-page {
  .no-pointers {
    pointer-events: none;
  }

  .v-step {
    inset: 0 0 auto -10px !important;
  }

  .first-step {
    margin-top: 250px;
  }
}

.zones-search {
  max-width: 400px;
}

.clients-filter {
  max-width: 400px;
  margin-bottom: -22px;
}
</style>
